import { api } from '.'

import axios from 'axios'

import { editUserFormDto } from './../components/forms/interfaces'

export const users_api = {
  recoverPassword: async (email: string) => {
    return api.post('/api/users/recover-password', {
      email: email,
    })
  },
  verifyRecoverPasswordToken: async (token: string) => {
    return api.post(`/api/users/verify-password-recovery-token`, {
      recoveryPassToken: token,
    })
  },
  changePassword: async (token: string, password: string) => {
    return api.post(`/api/users/change-password`, {
      recoveryPassToken: token,
      password: password,
    })
  },
  userUpdate: async (data: Partial<editUserFormDto>) => {
    return api.patch(`/api/users`, data)
  },
  userUploadProfPhoto: async (data: any) => {
    return await axios.post(
      `https://server.perevozka.global/api/users/upload-profile-image`,
      data,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },
  userDeleteProfPhoto: async () => {
    return api.delete(`/api/users/delete-profile-image`)
  },
  getUserInfo: async () => {
    return api.get(`/user/me`)
  },
  changePhone: async (phone: string) => {
    return api.post(`/user/changePhone`, { phone })
  },
}
