import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
import { tripRequestDto } from '@interfaces/dto/tripRequest.dto'

export interface ITripRequestSlice {
  userTrips: tripRequestDto[] | []
  getUserTrips: () => Promise<any>
}

export const useTripRequestSlice: StateCreator<ITripRequestSlice> = (set) => ({
  userTrips: [],
  getUserTrips: async () => {
    const response = await REQ.tripRequest_api.getTrips()

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, userTrips: data.orders }))
      return response
    }
  },
})
