import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
import { raceDto } from '@interfaces/dto/race.dto'

export interface IRacesSlice {
  foundRaces: raceDto[]
  getFindRaces: (IRacesFilter: any | null | undefined) => Promise<any>
}

export const useRacesSlice: StateCreator<IRacesSlice> = (set) => ({
  foundRaces: [],
  getFindRaces: async ({ fromLocationCountry, toLocationCountry }) => {
    const response = await REQ.race_api.getFindRaces(
      { page: 1, count: 6 },
      {
        fromLocationCountry,
        toLocationCountry,
      }
    )

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        foundRaces: data?.data,
      }))
      return response
    }
  },
})
