import { StateCreator } from 'zustand'

import { REQ } from '@api/index'

import { newsDto } from '@interfaces/dto/news.dto'

export interface INewsSlice {
  news: newsDto[] | []
  newsMeta: {
    page: number
    count: number
    total: number
    last_page: number
  }
  isNewsLoading: boolean
  getNews: (page: number, filter?: any | null | undefined) => Promise<any>
  newsItem?: newsDto | null
  isNewsItemLoading: boolean
  getNewsItemById: (id: string) => Promise<any>
}

export const useNewsSlice: StateCreator<INewsSlice> = (set) => ({
  news: [],
  newsMeta: {
    page: 0,
    count: 0,
    total: 0,
    last_page: 0,
  },
  isNewsLoading: false,
  getNews: async (page: number, filter?: any | null | undefined) => {
    set((state) => ({ ...state, isNewsLoading: true }))
    const response = await REQ.news_api.getNews(
      { page, count: 6 },
      filter ? filter : null,
    )

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isNewsLoading: false }))
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        news: [...state.news, ...data.data],
        newsMeta: data.meta,
        isNewsLoading: false,
      }))
      return response
    }
  },
  newsItem: null,
  isNewsItemLoading: false,
  getNewsItemById: async (id: string) => {
    set((state) => ({ ...state, isNewsItemLoading: true }))
    const response = await REQ.news_api.getNewsById(id)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isNewsItemLoading: false }))
      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        newsItem: data,
        isNewsItemLoading: false,
      }))
      return response
    }
  },
})
