import { api } from '.'

interface IMetaByCountryId {
  countryId: number
}

interface IMetaWithPagination {
  page: number
  count: number
  countryId: string
  filterByName: string
}

export const cities_api = {
  getCityById: async (id: string) => {
    return await api.get(`/api/travel/cities/user/${id}`)
  },
  getCitiesByCountryId: async ({ countryId }: IMetaByCountryId) => {
    return await api.get(`/travel/order/cities`, {
      params: {
        country_id: countryId,
      },
    })
  },
  getCitiesWithPagination: async ({
    page,
    count,
    countryId,
    filterByName,
  }: IMetaWithPagination) => {
    return await api.get(`/api/cities/user/getPaginate/${countryId}`, {
      params: {
        page,
        count,
        filterByName,
      },
    })
  },
}
