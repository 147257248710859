export const dark = {
  colors: {
    background: '#222222',
    primary: '#333333',
    secondary: '#444444',
    text: '#ffffff',
    accent: '#FFD700',
    accent2: '#339DFF',
  },
}

export const light = {
  colors: {
    background: '#f2f2f2',
    primary: '#8c8c8c',
    secondary: '#ffffcc',
    text: '#000',
    accent: '#FFD700',
    accent2: '#339DFF',
  },
}
