import { createGlobalStyle } from 'styled-components'

const GlobalFilePondStyles = createGlobalStyle`
    .react-datepicker {
        background-color: var(--secondary-color);
        // font-family: 'Mariupol';
    }

    .react-datepicker__header {
        background-color: var(--secondary-color);
    }
    
    .react-datepicker__current-month, .react-datepicker__day-name {
        color: var(--text-color);
    }

    .react-datepicker__day, .react-datepicker__day--keyboard-selected {
        background-color: var(--secondary-color);
        color: var(--text-color);
        transition: all 0.2s ease-in-out;
        &:hover {
            background-color: var(--text-color);
            color: var(--bg-color);
        }
    }

    .react-datepicker__day--in-range {
        background-color: var(--accent-color-2);
    }

    .react-datepicker__day--disabled {
        color: var(--primary-color);
        &:hover {
            background-color: var(--secondary-color);
            color: var(--primary-color);
        }
    }

    .react-datepicker__day--selected {
        background-color: var(--accent-color-2);
    }

    .react-datepicker__close-icon::after{
        background-color: var(--accent-color-2);
    }
`

export default GlobalFilePondStyles
