import { useEffect, useState } from 'react'
import { useAppStore } from '@store/useAppStore'
import { dark, light } from '@styles/themes/themes'

type ITheme = 'dark' | 'light'

export const useTheme = () => {
  const { theme, setTheme } = useAppStore((state) => state)
  const [themeStyles, setThemeStyles] = useState(
    theme === 'dark' ? dark : light
  )

  const handleTheme = () => {
    localStorage.setItem('theme', theme === 'dark' ? 'light' : 'dark')
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  useEffect(() => {
    const localTheme = localStorage.getItem('theme') as ITheme
    if (localTheme) {
      setTheme(localTheme === 'dark' ? 'dark' : 'light')
    }
  }, [])

  useEffect(() => {
    setThemeStyles(theme === 'dark' ? dark : light)
  }, [theme])

  return { theme, handleTheme, themeStyles }
}
