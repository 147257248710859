import { ReactSVG } from 'react-svg'

import styles from './LoadingWrapper.module.sass'

const LoadingWrapper = () => {
  return (
    <div className={styles.container}>
      <div className={styles.centerContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.logoContainer}>
            <ReactSVG src="/icons/logo-blue-yellow.svg" />
          </div>

          <div className={styles.perevozkaContainer}>
            <h1>PEREVOZKA</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingWrapper
