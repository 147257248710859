import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
import { countryDto } from '@interfaces/dto/country.dto'

export interface ICountriesSlice {
  countries: countryDto[]
  getCountries: () => Promise<void>
}

export const useCountriesSlice: StateCreator<ICountriesSlice> = (set) => ({
  countries: [],
  getCountries: async () => {
    const { data } = await REQ.countries_api.getCountries()
    set({ countries: data.countries })
  },
})
