import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html, * {
    scroll-behavior: smooth;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: 'Mariupol', sans-serif;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background-color: #222222;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
  }

  /* feedbacks and news */

  .slick-dots {
    bottom: -35px;
    div {
      background-color: ${({ theme }) => theme.colors.text};
    }
    li {
      margin: 0;
    }
    .slick-active {
      div {
        background-color: ${({ theme }) => theme.colors.accent};
      }
    }
  }

  .slick-active {
    button {
      background-color: ${({ theme }) => theme.colors.accent};
    }
  }

  /* main slider */

  .slick-dots-main-slider-blue {
    bottom: -35px;
    div {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.accent2};
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0 10px;
    }
    .slick-active {
      div {
        border: 2px solid ${({ theme }) => theme.colors.accent2};
        background-color: ${({ theme }) => theme.colors.secondary};
        width: 20px;
        height: 20px;
      }
    }
  }
          
  .slick-thumb-main-slider-yellow {
    bottom: -35px;
    div {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.accent};
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0 10px;
    }
    .slick-active {
      div {
        border: 2px solid ${({ theme }) => theme.colors.accent};
        background-color: ${({ theme }) => theme.colors.secondary};
        width: 20px;
        height: 20px;
      }
    }
  }

  .slick-thumb-main-slider-blue, .slick-thumb-main-slider-yellow {
    bottom: -35px;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    list-style: none;
  }

  /* themes */

  [data-theme='light'] {
    --bg-color: #f2f2f2;
    --inverted-bg-color-transparent: rgba(255, 255, 255, .4);
    --bg-color-transparent: rgba(0, 0, 0, 0.56),
    --primary-color: #8c8c8c;
    --primary-dark-color: #7c7c7c;
    --primary-color-transparent: rgb(140, 140, 140, .8);
    --secondary-color: #ffffcc;
    --text-color: #000;
    --light-accent-color: #B8B8B8;
    --accent-color: #FFD700;
    --accent-color-2: #339DFF;
    --alert-color: #ABB504;
    --table-dropdown-color: #5F5F5F;
    --gradient-color: #2a74a5;
    --gradient-color-2: #7989a4;
    
    --bg-color-blur: rgba(0, 0, 0, .5)
  }
  
  [data-theme='dark'] {
    --bg-color: #222222;
    --inverted-bg-color-transparent: rgba(255, 255, 255, .4);
    --bg-color-transparent: rgba(0, 0, 0, 0.56),
    --primary-light-color: #707070;
    --primary-color: #333333;
    --primary-dark-color: #2E2E2E; 
    --primary-color-transparent: rgb(51, 51, 51, .8);
    --secondary-color: #444444;
    --tertiary-color: #555555;
    --light-accent-color: #B8B8B8;
    --text-color: #fff;
    --accent-color: #FFD700;
    --accent-color-2: #339DFF;
    --alert-color: #FCA004;
    --table-dropdown-color: #5F5F5F;
    --gradient-color: #2D74D5;
    --gradient-color-2: #7989D4;

    --bg-color-blur: rgba(0, 0, 0, .5)
  }
`

export default GlobalStyles
