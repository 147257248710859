import { StateCreator } from 'zustand'

type ITheme = 'dark' | 'light'

export interface IGlobalSlice {
  loader: boolean
  toggleLoader: () => void
  theme: ITheme
  toggleTheme: () => void
  setTheme: (theme: ITheme) => void
  // isUserNavOpen: boolean
  // toggleUserNav: () => void
}

export const useGlobalSlice: StateCreator<IGlobalSlice> = (set) => ({
  loader: true,
  toggleLoader: () => set((state) => ({ loader: !state.loader })),
  theme: 'dark',
  toggleTheme: () =>
    set((state) => ({
      theme: state.theme === 'light' ? 'dark' : 'light',
    })),
  setTheme: (theme: ITheme) => set({ theme }),
  //   isUserNavOpen: false,
  //   toggleUserNav: () =>
  //     set((state) => ({ isUserNavOpen: !state.isUserNavOpen })),
  // fetchProducts: async () => {
  //     const res = await fetch('https://api.escuelajs.co/api/v1/products?offset=0&limit=20')
  //     set({ products: await res.json() })
  // },
})
