import { api } from '.'

import { loginDto, registerDto } from '@interfaces/dto/auth.dto'

export const auth_api = {
  login: async (data: loginDto) => {
    return api.post('/user/auth/login', data)
  },
  register: async (data: registerDto) => {
    return api.post(
      '/user/auth/register',
      //data without passwordConfirm
      {
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        middleName: data.middleName,
        birthDate: data.birthDate,
      },
    )
  },
  refresh: async () => {
    return api.post('/user/auth/refresh')
  },
  verifyEmail: async (token: string) => {
    return api.get(`/user/auth/verify-email`, {
      params: {
        token,
      },
    })
  },
  protected: async () => {
    return api.get('/user/auth/protected')
  },
}
