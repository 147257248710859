import { create } from 'zustand'

import { useGlobalSlice, IGlobalSlice } from '@store/slices/useGlobalStore'
import { ITripRequestSlice } from '@store/slices/useTripRequestStore'
import { ITripSlice } from '@store/slices/useTripStore'
import { ICountriesSlice } from './slices/useCountriesStore'
import { ICitiesSlice } from './slices/useCitiesStore'
import { IRacesSlice } from './slices/useRacesStore'
import { IRecoverPwSlice } from './slices/useRecoverPwStore'
import { INewsSlice } from './slices/useNewsStore'

import { useAuthSlice, IAuthSlice } from '@store/slices/useAuthStore'
import { useTripRequestSlice } from '@store/slices/useTripRequestStore'
import { useTripSlice } from '@store/slices/useTripStore'
import { useCountriesSlice } from './slices/useCountriesStore'
import { useCitiesSlice } from './slices/useCitiesStore'
import { useRacesSlice } from './slices/useRacesStore'
import { useRecoverPwSlice } from './slices/useRecoverPwStore'
import { useNewsSlice } from './slices/useNewsStore'

type StoreState = IGlobalSlice &
  IAuthSlice &
  ITripRequestSlice &
  ITripSlice &
  ICountriesSlice &
  ICitiesSlice &
  IRacesSlice &
  IRecoverPwSlice &
  INewsSlice

export const useAppStore = create<StoreState>()((...a) => ({
  ...useGlobalSlice(...a),
  ...useAuthSlice(...a),
  ...useTripRequestSlice(...a),
  ...useTripSlice(...a),
  ...useCountriesSlice(...a),
  ...useCitiesSlice(...a),
  ...useRacesSlice(...a),
  ...useRecoverPwSlice(...a),
  ...useNewsSlice(...a),
}))
