import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'styled-components'

import { useTheme } from '@hooks/useTheme'

import AuthWrapper from '@components/wrappers/AuthWrapper'
import LoadingWrapper from '@components/wrappers/LoadingWrapper/LoadingWrapper'

import GlobalStyles from '@styles/GlobalStyles'
import GlobalFilePondStyles from '@styles/GlobalFilePondStyles'
import GlobalCalendarStyles from '@styles/GlobalCalendarStyles'
import { light, dark } from '@styles/themes/themes'

import 'react-toastify/dist/ReactToastify.css'
import '@styles/index.sass'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { theme } = useTheme()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true)
    }

    const handleRouteComplete = () => {
      setLoading(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteComplete) // If the component is unmounted, unsubscribe

    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme == 'dark' ? dark : light}>
        <div data-theme={theme}>
          <GlobalStyles />
          <GlobalFilePondStyles />
          <GlobalCalendarStyles />
          <AuthWrapper>
            {loading ? <LoadingWrapper /> : <Component {...pageProps} />}
          </AuthWrapper>
        </div>
      </ThemeProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
