import { StateCreator } from 'zustand'

import { IRtkStatus } from '@interfaces/global.type'

export interface IRecoverPwSlice {
  rtkStatus: IRtkStatus
  setRtkStatus: (rtk: IRtkStatus) => void
  rtk: string
  setRtk: (rtk: string) => void
}

export const useRecoverPwSlice: StateCreator<IRecoverPwSlice> = (set) => ({
  rtkStatus: 'getRtkStatus',
  setRtkStatus: (rtkStatus: IRtkStatus) => set({ rtkStatus }),
  rtk: '',
  setRtk: (rtk: string) => set({ rtk }),
})
