import { api } from '.'

import { IMeta } from '@interfaces/general'

interface ILocalMeta {
  page: number
  count: number
  backTripPage: number
  backTripCount: number
}

export const trip_api = {
  getInitialFinalTrips: async (
    { page, count }: IMeta,
    ITripsFilter: any | null | undefined,
  ) => {
    return await api.get('/travel/order/trips', {
      params: {
        page,
        count,
        filter: ITripsFilter ? JSON.stringify(ITripsFilter) : null,
      },
    })
  },
  getNearestTrips: async (
    { page, count }: IMeta,
    ITripsFilter: any | null | undefined,
  ) => {
    return await api.get('/api/trip/user/getPaginate', {
      params: {
        page,
        count,
        filter: ITripsFilter ? JSON.stringify(ITripsFilter) : null,
      },
    })
  },
  getFindTrips: async (ITripsFilter: any | null | undefined) => {
    return await api.post('/travel/order/trips', {
      ...ITripsFilter,
    })
  },
  getTripById: async (id: string) => {
    return await api.get(`/api/trip/user/get-one/${id}`)
  },
  getTransportPhotos: async (id: number) => {
    return await api.get(`/travel/cars/${id}/photo`)
  },
}
