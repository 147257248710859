import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
import {
  tripDto,
  IFoundTrip,
  IAnotherDateTrips,
} from '@interfaces/dto/trip.dto'

export interface ITripSlice {
  initialFinalTrips: tripDto[] | []
  getInitialFinalTrips: () => Promise<any>
  isTripsLoading: boolean
  // nearestTrips: tripDto[] | []
  // getNearestTrips: () => Promise<any>
  foundTrips: tripDto[] | []
  foundBackTrips: tripDto[] | []
  getFindTrips: (IFoundTrip: IFoundTrip) => Promise<any>
  anotherDateTrips: tripDto[] | []
  anotherDateBackTrips: tripDto[] | []
  getAnotherDateTrips: (IAnotherDateTrips: IAnotherDateTrips) => Promise<any>
  tripIdToOrder: number
  setTripIdToOrder: (tripId: number) => void
  getTransportPhotos: (id: number) => Promise<any>
}

export const useTripSlice: StateCreator<ITripSlice> = (set) => ({
  initialFinalTrips: [],
  getInitialFinalTrips: async () => {
    const currentTimestamp = new Date().setHours(0, 0, 0, 0)

    const response = await REQ.trip_api.getInitialFinalTrips(
      {
        page: 1,
        count: 10,
      },
      {
        status: 'active',
        departureDate: {
          startDate: currentTimestamp,
          endDate: null,
        },
      },
    )

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      set((state) => ({ ...state, initialFinalTrips: data.data }))
      return response
    }
  },
  isTripsLoading: false,
  foundTrips: [],
  foundBackTrips: [],
  getFindTrips: async ({
    arrival_city_id: toLocationCity,
    arrival_country_id: toLocationCountry,
    departure_city_id: fromLocationCity,
    departure_country_id: fromLocationCountry,
    departure_time,
    return_time,
    return_trip = false,
  }: IFoundTrip) => {
    set((state) => ({ ...state, isTripsLoading: true }))

    try {
      const response = await REQ.trip_api.getFindTrips({
        departure_country_id: fromLocationCountry,
        departure_city_id: fromLocationCity,
        arrival_country_id: toLocationCountry,
        arrival_city_id: toLocationCity,
        departure_time,
        return_time,
        return_trip,
      })

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        set((state) => ({
          ...state,
          foundTrips: data?.trips,
          foundBackTrips: return_trip && data?.return_trips,
        }))
        return response
      }
    } catch (error) {
      console.log(error)
      set((state) => ({ ...state, foundTrips: [], foundBackTrips: [] }))
    } finally {
      set((state) => ({ ...state, isTripsLoading: false }))
    }
  },
  anotherDateTrips: [],
  anotherDateBackTrips: [],
  getAnotherDateTrips: async ({
    departure_time,
    return_time,
    return_trip = false,
  }: IAnotherDateTrips) => {
    set((state) => ({ ...state, isTripsLoading: true }))

    try {
      const response = await REQ.trip_api.getFindTrips({
        departure_time,
        return_time,
        return_trip,
      })
      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        set((state) => ({
          ...state,
          anotherDateTrips: data?.trips,
          anotherDateBackTrips: return_trip && data?.return_trips,
        }))
        return response
      }
    } catch (error) {
      console.log(error)
    } finally {
      set((state) => ({ ...state, isTripsLoading: false }))
    }
  },
  tripIdToOrder: 0,
  setTripIdToOrder: (tripId: number) => {
    set((state) => ({ ...state, tripIdToOrder: tripId }))
  },
  getTransportPhotos: async (id: number) => {
    const response = await REQ.trip_api.getTransportPhotos(id)
    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      throw new Error(data)
    } else {
      return response
    }
  },
})
