import { api } from '.'

import { IMeta } from '@interfaces/general'

export const news_api = {
  getNews: async ({ page, count }: IMeta, filter: any | null | undefined) => {
    return await api.get('/api/news/user/get-paginate', {
      params: {
        page,
        count,
        filter: filter ? JSON.stringify(filter) : null,
      },
    })
  },
  getNewsById: async (id: string) => {
    return await api.get(`/api/news/user/get-one/${id}`)
  },
  getLatestNews: async (
    { page, count }: IMeta,
    filter: any | undefined | null,
    sort: any | undefined | null,
  ) => {
    return await api.get('/api/news/user/get-paginate', {
      params: {
        page,
        count,
        filter: filter ? JSON.stringify(filter) : null,
        sort: sort ? JSON.stringify(sort) : null,
      },
    })
  },
}
