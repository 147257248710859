import { api } from '.'

interface ILocalMeta {
  page: number
  count: number
  filterByName: string
}

export const countries_api = {
  getCountries: async () => {
    return await api.get('/travel/order/countries')
  },
  getCountryById: async (id: string) => {
    return await api.get(`/api/countries/user/${id}`)
  },
  getCountriesWithPagination: async ({
    page,
    count,
    filterByName,
  }: ILocalMeta) => {
    return await api.get('/api/countries/user/getPaginate', {
      params: {
        page,
        count,
        filterByName,
      },
    })
  },
}
