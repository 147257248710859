import { createGlobalStyle } from 'styled-components'

const GlobalFilePondStyles = createGlobalStyle`
    .filepond--wrapper {
        width: 100%;
        min-height: 110px;
        border: 2px dashed var(--text-color);
    }
    
    .filepond--root {
        min-height: 146px;
        margin-bottom: 0;
        background-color: transparent;
    }
    
    .filepond--drop-label {
        height: 100%;
        background-color: var(--bg-color);
        color: var(--text-color);
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        font-family: 'Mariupol';
        label {
            width: 80%;
            div {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                p {
                    font: var(--font-md19);
                }
            }
            .icon {
                width: 80%;
                display: flex;
                flex-direction: row;
                aign-items: center;
                justify-content: flex-end;
            }
        }
    }

    @media (max-width: 1536px) {
        .filepond--wrapper {
            min-height: 110px;
        }
        .filepond--root {
            min-height: 128px;
        }
        .filepond--drop-label {
            label {
                width: 90%;
                p {
                    font: var(--font-md18);
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .filepond--root {
            min-height: 168px;
        }
        .filepond--drop-label {
            label {
                width: 92%;
            }
        }
    }
`

export default GlobalFilePondStyles
