import { api } from '.'

import { createTripRequestDto } from '@interfaces/dto/tripRequest.dto'

export const tripRequest_api = {
  createTrip: async (data: createTripRequestDto) => {
    return api.post(`/travel/order/trips/create`, data)
  },
  getTrips: async () => {
    return await api.get(`/user/myOrders`)
  },
}
