import { api } from '.'

interface IConsultationDto {
  name: string
  phoneNumber: string
  type: 'passengerTransportation' | 'cargoTransportation'
  category: 'leaveNumber'
}

export const consultation_api = {
  sendConsultation: async (consultationDto: IConsultationDto) => {
    return await api.post('/api/consultation/user', consultationDto)
  },
}
