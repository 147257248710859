import { StateCreator } from 'zustand'

import { REQ } from '@api/index'
// import { IMeta } from '@interfaces/general'
import { cityDto } from '@interfaces/dto/city.dto'

export interface ICitiesSlice {
  cities: cityDto[]
  getCitiesAllByCountry: (id: number) => Promise<any>
}

export const useCitiesSlice: StateCreator<ICitiesSlice> = (set) => ({
  cities: [],
  getCitiesAllByCountry: async (id: number) => {
    const { data } = await REQ.cities_api.getCitiesByCountryId({
      countryId: id,
    })

    //? add to store if city with the same id doesn't exist
    set((state) => {
      if (!state.cities.some((city) => city.city_id === data.city_id)) {
        return { cities: [...state.cities, data] }
      }
    })
  },
})
