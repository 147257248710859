import { api } from '.'

import { IMeta } from '@interfaces/general'

export const race_api = {
  getFindRaces: async (
    { page, count }: IMeta,
    IRacesFilter: any | null | undefined
  ) => {
    return await api.get('/api/races/user/getPaginate', {
      params: {
        page,
        count,
        filter: IRacesFilter ? JSON.stringify(IRacesFilter) : null,
      },
    })
  },
}
